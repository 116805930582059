.uppy-Webcam-container {
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column; }

.uppy-Webcam-videoContainer {
  width: 100%;
  -ms-flex: 1;
      flex: 1;
  -ms-flex-positive: 1;
      flex-grow: 1;
  overflow: hidden;
  background-color: #333;
  text-align: center;
  position: relative; }

.uppy-Webcam-video {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto; }

.uppy-Webcam-video--mirrored {
  transform: scaleX(-1); }

.uppy-Webcam-buttonContainer {
  width: 100%;
  height: 75px;
  border-top: 1px solid #eaeaea;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 0 20px; }

.uppy-Webcam-button {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #e32437;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s; }

.uppy-Webcam-button svg {
  width: 30px;
  height: 30px;
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  vertical-align: text-top;
  overflow: hidden;
  fill: currentColor; }

.uppy-size--md .uppy-Webcam-button {
  width: 60px;
  height: 60px; }

.uppy-Webcam-button:hover {
  background-color: #d31b2d; }

.uppy-Webcam-button:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(34, 117, 215, 0.5); }

.uppy-Webcam-button--picture {
  margin-right: 12px; }

.uppy-Webcam-permissons {
  padding: 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-flow: column wrap;
      flex-flow: column wrap;
  height: 100%;
  -ms-flex: 1;
      flex: 1; }

.uppy-Webcam-permissons p {
  max-width: 450px;
  line-height: 1.3; }

.uppy-Webcam-title {
  font-size: 22px;
  line-height: 1.35;
  font-weight: 400;
  margin: 0;
  margin-bottom: 5px;
  padding: 0 15px;
  max-width: 500px;
  text-align: center;
  color: #333; }

.uppy-Webcam-permissons p {
  text-align: center;
  line-height: 1.45;
  color: #939393;
  margin: 0; }

.uppy-Webcam-permissonsIcon svg {
  width: 100px;
  height: 75px;
  color: #bbb;
  margin-bottom: 30px; }
